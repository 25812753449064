<template>
  <div class="fd-w-full fd-bg-white fd-px-4 fd-py-6">
    <Table
      :name="name"
      :total="total"
      :fields="fields"
      :items="projectAgents"
      :hasFilter="false"
      :hasSearch="true"
      :perPage="10"
      :isBusy="isBusy"
      :hasImageColumn="true"
      @changedPage="setPage"
      @setSearch="setSearch"
      :hasEditIcon="false"
      @showEditComponent="showEditComponent"
      @showRemoveComponent="showRemoveComponent"
    />

    <ConfirmDialogue @remove="removeAgent" />

    <b-sidebar
      id="invite-sidebar-id"
      no-header
      bg-variant="white"
      shadow right lazy backdrop
      :backdrop-variant="'dark'"
    >
      <template #default="{ hide }">
        <div class="fd-flex fd-items-center fd-justify-between fd-p-3" style="background-color: #E9EDEB;">
          <span class="fd-text-theme-2 fd-text-base">{{ $t('internalAgents.invite') }}</span>
          <div @click="inviteSidebar" class="fd-cursor-pointer">
            <SvgLoader :name="'close'" />
          </div>
        </div>
        <div class="fd-p-3">
          <span class="fd-text-lg fd-font-bold fd-text-theme-11">{{ $t('internalAgents.inviteToProject') }}</span>
          <p class="fd-text-base fd-text-theme-9 fd-mt-2">{{ $t('global.inviteAnAgentToProject') }}</p>
          <div class="agent--dropdown">
            <multiselect
              id="agent"
              v-model="agentSelected"
              label="first_name"
              :custom-label="firstNameWithLastName"
              select-label=""
              deselect-label=""
              :options="agents"
              track-by="id"
            />
            <div class="fd-w-full fd-flex fd-items-center fd-justify-between fd-absolute fd-left-0 fd-bottom-0 fd-p-3">
              <b-button @click="invite" variant="primary" class="fd-mr-4 fd-w-full" style="font-size: 14px !important;">
                {{ $t('internalAgents.grantAccess') }}
              </b-button>
              <b-button @click="inviteSidebar" variant="outline-danger" class="fd-w-full" style="font-size: 14px !important;">
                {{ $t('global.cancel') }}
              </b-button>
            </div>
          </div>
        </div>
      </template>

    </b-sidebar>
  </div>
</template>

<script>
import Table from "@/views/components/global/Table";
import { BModal, BButton, BSidebar } from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import 'vue-search-select/dist/VueSearchSelect.css'
import {bus} from "@/main";
import {mapGetters} from "vuex";
import toast from "@/mixins/toast";
import ConfirmDialogue from "@/views/components/global/ConfirmDialogue";
import DataPreparation from "@/libs/dataPreparation";
export default {
  name: "InternalAgents",
  components: {ConfirmDialogue, Table, BModal, BButton, Multiselect, BSidebar},
  mixins: [toast],
  data() {
    return {
      loading: false,
      agentSelected: '',
      btnTitle: null,
      search: '',
      itemId: null
    }
  },
  created() {
    this.btnTitle = this.$route.meta.button.btnTitle
    bus.$on(this.btnTitle, () => {
      this.inviteSidebar()
    })

    this.loading = true
    let data = {
      id: this.$route.params.id
    }
    this.getData(data)

    this.$store.dispatch('project/getAgents', this.$route.params.id)
  },
  watch: {
    search: {
      handler(newVal) {
        let data = {
          id: this.$route.params.id,
        }
        if (newVal) {
          data.search = newVal
        }
        this.getData(data)
      }
    }
  },
  computed: {
    ...mapGetters({
      'projectAgents': 'project/projectAgents',
      'total': 'project/projectAgentsTotal',
      'agents': 'project/agents'
    }),
    fields() {
      return [
        { key: 'avatar', label: this.$t('table.pic')},
        {
          key: 'first_name',
          label: this.$t('table.agentName'),
          sortable: true, sortDirection: 'desc',
          formatter: (value, key, data) => {
            return data.first_name + ' ' + data.last_name
          }
        },
        {
          key: 'languages',
          label: this.$t('table.language'),
          formatter: (value) => {
            return value.length
          }
        },
        { key: 'appointment_count', label: this.$t('table.appointment') },
        {
          key: 'total_sold.USD',
          label: this.$t('table.totalSold'),
          formatter: (value) => {
            return DataPreparation.thousandSeparators(value)
          }
        },
        { key: 'actions', label: this.$t('table.actions'), class: 'text-right' }
      ]
    },
    name() {
      return this.$t('internalAgents.internalAgents')
    },
    isBusy() {
      return this.loading
    }
  },
  methods: {
    getData(data) {
      this.loading = true
      this.$store.dispatch('project/getProjectAgents', data)
        .finally(() => this.loading = false)
    },
    setFilters(filters) {
      console.log(filters)
    },
    setPage(number) {
      let data = {
        id: this.$route.params.id,
        number
      }
      if (this.search) {
        data.search = this.search
      }
      this.getData(data)
    },
    setSearch(search) {
      this.search = search
    },

    showEditComponent(id) {
      this.agents.forEach(item => {
        if (item.id === id) {
          this.agentSelected = item
        }
      })

      this.$root.$emit('bv::toggle::collapse', 'invite-sidebar-id')
    },

    showRemoveComponent(id) {
      this.itemId = id
      this.toggleConfirmDialogue()
    },
    toggleConfirmDialogue() {
      this.$root.$emit("bv::toggle::modal", "confirmDialogue");
    },
    removeAgent() {
      this.$store.dispatch('project/deleteAgent', {
        id: this.$route.params.id,
        data: {
          agentId: this.itemId
        }
      }).then(response => {
        this.makeToast(response.data.message)
        let data = {
          id: this.$route.params.id,
        }
        if (this.search) {
          data.search = this.search
        }
        this.getData(data)
      })
        .finally(() => {
          this.itemId = null
          this.toggleConfirmDialogue()
        })
    },
    inviteSidebar() {
      this.agentSelected = ''
      this.$root.$emit('bv::toggle::collapse', 'invite-sidebar-id')
    },
    invite() {
      this.$store.dispatch('project/inviteUser', {
        id: this.$route.params.id,
        data: {
          agent_id: this.agentSelected.id
        }
      }).then(response => {
        this.makeToast(response.data.message)
        this.inviteSidebar()
        let data = {
          id: this.$route.params.id,
        }
        if (this.search) {
          data.search = this.search
        }
        this.getData(data)
      })
    },
    firstNameWithLastName ({ first_name, last_name }) {
      return `${first_name} ${last_name}`
    }
  },
  beforeDestroy() {
    bus.$off(this.btnTitle)
  },
}
</script>

<style lang="scss" scoped>
.agent--dropdown {
  ::v-deep div.dropdown.b-dropdown.btn-group {
    width: 100% !important;

    & ::v-deep button.btn.dropdown-toggle.btn-outline-dark {
      text-align: left !important;
    }
  }
}
</style>