<template>
  <div>
    <b-modal :id="getId" centered hide-footer hide-header>
      <div class="fd-grid fd-grid-cols-1 fd-justify-center fd-w-7/12 fd-mx-auto fd-py-6">
        <SvgLoader :name="'dangerIcon'" class="fd-mx-auto" />
        <h1 class="fd-text-2xl fd-font-medium fd-text-theme-11 fd-text-center fd-mt-5 fd-mb-2">{{ $t('confirmDialogue.areYouSure') }}</h1>
        <p class="fd-text-base fd-font-normal fd-text-theme-9 fd-text-center fd-mb-6">
          {{ $t('confirmDialogue.youWontBeAbleToRevertThis') }}
        </p>
        <div class="fd-flex fd-items-center">
          <b-button @click="$emit('remove')" size="sm" block variant="primary" class="fd-mr-2">
            <span class="fd-text-sm fd-font-normal">{{ $t('confirmDialogue.yesDeleteIt') }}</span>
          </b-button>
          <b-button @click="cancel" size="sm" block variant="outline-danger" style="margin-top: 0 !important;">
            <span class="fd-text-sm fd-font-normal">{{ $t('global.cancel') }}</span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {BModal, BButton} from 'bootstrap-vue'

export default {
  name: "ConfirmDialogue",
  components: { BModal, BButton },
  props: {
    id: {
      type: Number | String,
      default: 0
    }
  },
  computed: {
    getId() {
      if (this.id) {
        if (typeof this.id === 'string') {
          return this.id
        }
        return `confirmDialogue-${this.id}`
      }
      return 'confirmDialogue'
    }
  },
  methods: {
    cancel() {
      this.$root.$emit("bv::toggle::modal", this.getId);
    }
  }
}
</script>

<style scoped>

</style>