import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  methods: {
    makeToast(
      text = '',
      title = '',
      icon = 'BellIcon',
      variant = 'success'
    ) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    }
  },
}